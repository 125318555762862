.Page_heading_SpeakerVideo {
  & .Add_Head_Button {
    & img {
      margin-right: 0 !important;
    }
  }
}

.Thumbnail_Img_name_container {
  & img {
    padding: 1rem;
  }
}

.Name_discription_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  & .name_created_by {
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #3f434a;
  }

  & .discription_created_by {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #8a9099;
  }
}

.Published_custom_button {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #20a144;

  background-color: #f1fbf4;
  padding: 0.5rem 1rem;
  border: 0;
  border-radius: 10px;
}

.add_speaker_video_main {
  & .speaker_video_head {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & h6 {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #002566;
      border-bottom: 1px solid #002566;
    }

    .head_buttons_container {
      display: flex;
      gap: 20px;
      position: relative;

      & .publish_option_container {
        display: flex;
        flex-direction: column;
        gap: 11px;
        position: absolute;
        top: 110%;
        right: 0%;
        padding: 0;
        background-color: white;
        box-shadow: none;
        z-index: 1;
        border-radius: 10px;

        & span {
          font-weight: 400;
          font-size: 15px;
          line-height: 22px;
          color: #3f434a;
          cursor: pointer;
        }
      }

      // & button:nth-of-type(1) {
      //   border: 1px solid #e8e9eb;
      //   font-weight: 600;
      //   font-size: 15px;
      //   line-height: 22px;
      //   color: #595f69;
      //   padding: 0.5rem 2rem;
      //   border-radius: 10px;
      // }
      & .custom_publish_button {
        border: 1px solid #304ffd;
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;
        color: #304ffd;
        background-color: white;
        padding: 0.5rem 2rem;
        border-radius: 10px;

        & img {
          margin-left: 10px;
        }
      }
    }
  }

  & .body_add_speaker {
    h1 {
      font-weight: 600;
      font-size: 28px;
      line-height: 42px;

      color: #3f434a;
    }

    & .Add_speaker_video_form {
      & label {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #8a9099;
      }

      & input {
        border-radius: 1rem !important;
      }

      & .add_read_more_section_button {
        background-color: white;
        border: none;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #002566;
        margin-bottom: 15px;
        margin-top: 15px !important;
      }

      & .next_cancel_button_container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;

        & button:nth-of-type(1) {
          border: 1px solid #002566;
          background-color: #002566;
          font-weight: 500;
          font-size: 15px;
          line-height: 22px;
          color: white;
          padding: 0.5rem 2rem;
          border-radius: 1rem;
        }

        & button:nth-of-type(2) {
          border: 1px solid #e8e9eb;
          font-weight: 500;
          font-size: 15px;
          line-height: 22px;
          color: #595f69;
          padding: 0.5rem 2rem;
          border-radius: 1rem;
        }
      }

      & .setting_heading {
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        color: #3f434a;
        text-transform: uppercase;
      }

      & .member_type {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #595f69;
      }

      & .status_and_radio {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & button {
          width: max-content;
        }

        & .status_radio_button {
          margin-right: 0px;
          margin-left: 10px;

          & .radio_button_background .MuiSwitch-track {
            background-color: #002566 !important;
          }
        }
      }

      .free_premium_container {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 15px;
        width: 65%;

        .free_Font {
          text-align: end;
        }

        & .free_Font,
        .premium_Font {
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #a3a3a3;
          width: 100%;
        }
      }

      & .member_free_button_container {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      & .form-group {
        margin-bottom: 15px;
      }

      & .list_of_speakers_container {
        & ul {
          list-style: none;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 20px;

          & li {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 10px;

            & img {
              height: 30px !important;
              width: auto;
            }

            & p {
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: #3f434a;
            }
          }

          & li:nth-last-of-type(1) p {
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            text-transform: uppercase;
            color: #002566;
          }
        }
      }

      & .custom_audio {
        width: 80%;
        height: 2.5rem;
        border: 1px solid lightgrey;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        cursor: pointer;

        & img {
          margin-right: 2px;
        }
      }

      & .custom_audio1 {
        width: 80%;
        height: 4rem;
        border: 1px solid lightgrey;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        cursor: pointer;

        & img {
          margin-right: 2px;
        }
      }

      & fieldset {
        border: 1px solid lightgrey;
        border-radius: 10px;

        & legend {
          & span {
            display: none;
          }
        }
      }

      & .For_chip_section {
        width: 100% !important;

        & input {
          padding: 10px 15px !important;
        }
      }
    }
  }
}

.custom_status_radio_button {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.speaker_avatar_img_overlay {
  & img {
    margin-left: -15px;
  }
}

.speaker_video_list_container {
  border: 8px solid black;
  border-radius: 25px;
  padding: 1rem;

  & .SpeakerVideo_DescPara {
    & h4 {
      font-weight: 600;
      font-size: 16px;
      line-height: 25px;
      color: #111111;
      margin-top: 10px;
    }

    & p {
      font-weight: 300;
      font-size: 16px;
      line-height: 25px;
      color: #111111;
    }
  }

  & .listen_ready_containers {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & button {
      font-weight: 600;
      font-size: 11px;
      line-height: 13px;
      color: #111111;
      background-color: white;
      padding: 0.5rem 0.9rem;
      border: 1px solid #111111;
      border-radius: 10px;
    }
  }

  & .bottom_black_line {
    padding: 0.2rem;
    background-color: #010101;
    width: 60%;
    display: flex;
    border-radius: 10px;
  }

  & .preview_time_icons_container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & p {
      font-weight: 600;
      font-size: 17px;
      line-height: 22px;
      color: #010101;
      margin-bottom: 0;
    }

    & .signal_other_Icons {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
    }
  }

  & .skeleton_square_container1 {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: space-between;
  }

  & .preview_HeadingParaTringle {
    & h1 {
      font-weight: 700;
      font-size: 28px;
      line-height: 34px;
      color: #111111;
    }

    & p {
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      color: #686868;
    }

    & .skeleton_rectangular_container {
      display: flex;
      flex-direction: row !important;
      align-items: center;
      justify-content: flex-start;
      gap: 20px;
    }
  }

  & .speaker_video_details_container {
    margin-top: 20px;
    text-align: center;

    & .custom_thumbnain_img {
      width: 100%;
      height: auto;
      aspect-ratio: 343/193;
      border-radius: 8px;
    }

    & .custom_thumbnain_img1 {
      width: 100%;
      height: auto;
      aspect-ratio: 343/205;
      border-radius: 8px;
    }

    & .flex_container_detail {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 15px;
      text-align: left;

      & .custom_skeleton_details {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 5px;
        width: 40%;
        margin-bottom: 30px;
      }

      & .detail_icons {
        display: flex;
        align-items: center;
        justify-content: left;
        gap: 15px;
      }
    }

    & .speaker_title_disc {
      & h6 {
        font-weight: 600;
        font-size: 16px;
        line-height: 25px;
        color: #111111;
      }

      & span {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #939393;
      }

      & p {
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: #686868;
      }
    }
  }
}

.custom_speaker_video_pageHeight {
  max-height: 120vh;
  overflow-y: scroll;
  position: relative;
  padding-bottom: 0;

  & .bottom_black_line {
    margin-bottom: 20px;
  }

  & .Video_action_icon_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  & .speaker_video_footer {
    position: sticky;
    bottom: 0;
    left: 0;
    z-index: 2;
    background-color: white;
  }
}

.custom_speaker_video_pageHeight::-webkit-scrollbar {
  width: 0 !important;
}

.publish_close_button_container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
  background-color: white;

  & button:nth-of-type(1) {
    background-color: #002566;
    border: 1px solid #002566;
    border-radius: 1rem;
    color: white;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    padding: 0.5rem 2rem;
  }

  & button:nth-of-type(2) {
    background-color: white;
    border: 1px solid #595f69;
    border-radius: 1rem;
    color: #595f69;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    padding: 0.5rem 2rem;
  }
}

.select_main_container {
  .category_tile {
    background-color: white;
    width: 100%;
  }

  .remove_category {
    right: 0%;
  }
}

.underLine_section {
  border-bottom: 1px solid #e8e9eb;
  width: 50%;
  display: flex;
}

.Add_Speaker_box_border {
  fieldset,
  .MuiSelect-select {
    border: 1px solid lightgrey;
    border-radius: 1rem !important;
  }

  .MuiSelect-select:focus {
    border: 1px solid lightgrey !important;
    // outline: 1px solid lightgrey !important;
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: lightgrey !important;
    border-width: 0 !important;
  }
}

.viewCommunityCancel {
  border: 1px solid #e8e9eb;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #595f69;
  padding: 0.5rem 2rem;
  border-radius: 1rem;
}

.downloadPollButton {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;

  & button:nth-of-type(1) {
    border: 1px solid #002566;
    background-color: #002566;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: white;
    padding: 0.5rem 2rem;
    border-radius: 1rem;
  }
}
