// .radio_button_background .MuiSwitch-track {
//     background-color: #002566 !important;
//   }
.radio_lable_inline {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
  label {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #8a9099;
  }
  .css-113qkos-MuiSwitch-root .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
    background-color: #002566;
  }
}
