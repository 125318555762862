.search_bar_container {
    width: 85%;
  & span {
    height: 100%;
    width: 100%;
    background-color: white;
  }
  & .search_container {
    border-radius: 10px 0px 0px 10px;
  }
  & .filter_container {
    border-radius: 0px 10px 10px 0px;
    border-left: 0;
  }
  & input {
    border-left: 0px;
    border-right: 0px;
  }
  & input:focus {
    box-shadow: none;
    border-color: #ced4da;
  }
}
.actionButton_searchBar_container {

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2vh;

  & .action_button {
    border: 1px solid #e8e9eb !important;
    background-color: white;
    border-radius: 10px;

    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #3f434a;
    height: 100%;
    padding: 0.5rem 1rem;

    & img{
        margin-left: 10px;
    }
  }
}
