.certifiedNonCertified_container {
  & button {
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #8a9099 !important;
    text-transform: capitalize !important;
    width: 20%;
  }
  & .MuiTabs-indicator {
    background-color: #002566 !important;
  }
}

.custom_audio {
  width: 80%;
  height: 2.5rem;
  border: 1px solid lightgrey;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around !important;
  cursor: pointer;
  & img {
    margin-right: 2px;
  }
}
 .custom_audio1 {
  width: 80%;
  height: 4.0rem;
  border: 1px solid lightgrey;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  & img {
    margin-right: 2px;
  }
}

.heading_publish_container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & h1 {
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    color: #3f434a;
    margin-bottom: 0;
  }
  & .custom_publish_button {
    border: 1px solid #304ffd;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: #304ffd;
    background-color: white;
    padding: 0.5rem 2rem;
    border-radius: 10px;
  }
}

.form_add_case_study {
  .form-group {
    margin-bottom: 15px;
  }
  .next_cancel_button_container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;

    & button:nth-of-type(1) {
      border: 1px solid #002566;
      background-color: #002566;
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      color: white;
      padding: 0.5rem 2rem;
      border-radius: 10px;
    }
    & button:nth-of-type(2) {
      border: 1px solid #e8e9eb;
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      color: #595f69;
      background-color: white;
      padding: 0.5rem 2rem;
      border-radius: 10px;
    }
  }
  label {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #8a9099;
  }
  .setting_heading {
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: #3f434a;
    text-transform: uppercase;
  }
  .custom_audio {
    width: 70%;
    height: 2.5rem;
    border: 1px solid lightgrey;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: end;
    & img {
      margin-right: 10px;
    }
  }
  .member_free_button_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .member_type {
      margin-bottom: 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #595f69;
    }
    .free_premium_container {
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 15px;
      width: 65%;
      .free_Font {
        text-align: end;
      }
      & .free_Font,
      .premium_Font {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #a3a3a3;
        width: 100%;
      }
    }
  }
  .For_chip_section {
    margin-left: 0px;
    width: 100% !important;
    & input {
      padding: 10px 15px !important;
    }
  }
  .MuiInputBase-input {
    padding: 6.5px 14px !important;
  }
  fieldset {
    border: 1px solid lightgrey;
    border-radius: 1rem;

    & legend {
      & span {
        display: none;
      }
    }
  }
}

.preview_case_study_main {
  .heading_preview_mode {
    font-weight: 500;
    font-size: 28px;
    line-height: 42px;
    color: #3f434a;
  }
  .case_study_list_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

    .case_study_list_text {
      .study_name_photo_container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 5px;
        img {
          width: 20px;
          height: 20px;
        }
        p {
          font-weight: 500;
          font-size: 13px;
          line-height: 18px;
          color: #686868;
          margin-bottom: 0px;
        }
      }
      .study_title_date_container {
        h4 {
          font-weight: 600;
          font-size: 16px;
          line-height: 25px;
          color: #111111;
          margin-bottom: 0px;
          margin-top: 10px;
        }
        p {
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: #686868;
        }
        span {
          font-weight: 400;
          font-size: 11px;
          line-height: 13px;
          color: #686868;
        }
      }
      .skelaton_title_date_container {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        gap: 5px;
        margin-top: 10px;
      }
    }
    .case_study_img_share_container {
      .list_main_img {
        width: 100px;
        height: 80px;
        border-radius: 8px;
      }
      .detail_icons {
        display: flex;
        align-items: center;
        justify-content: right;
        gap: 15px;
        margin-top: 10px;
      }
    }
  }
  .case_study_detail_preview {
    .pre_mainImg_text {
      font-weight: 300;
      font-size: 16px;
      line-height: 25px;
      color: #111111;
      margin-top: 10px;
    }
    .case_author_name_time_container {
      p {
        margin-top: 0.3rem;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: #686868;
      }
    }
    .image_author_container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      img {
        width: 20px;
        height: 20px;
      }
      p {
        display: flex;
        margin-bottom: 0;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: #686868;
        h6 {
          margin-bottom: 0;
          font-weight: 600;
          font-size: 13px;
          line-height: 18px;
          color: #686868;
        }
        h6:last-child {
          // text-decoration: underline;
        }
      }
    }
  }
}
.custom_thumbnain_img {
  margin-bottom: 15px;
}
.publish_option_container {
  display: flex;
  flex-direction: column;
  gap: 11px;
  position: absolute;
  top: 110%;
  right: 0%;
  padding: 2rem 4rem 2rem 1rem;
  background-color: white;
  box-shadow: 0px 0px 3px rgb(0 0 0 / 36%);
  z-index: 1;
  border-radius: 10px;

  & span {
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #3f434a;
    cursor: pointer;
  }
}
.custom_left_padding {
  padding-left: 1rem;
}
.fixWidthCell {
  max-width: 15vw !important;
}

.firestScreenAuthor {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #686868;
}

.custom_font {
  .tox-toolbar__primary {
    .tox-toolbar__group:nth-child(2) {
      span {
        font-weight: 700 !important;
      }
    }
  }
}
