.custom_table_css {
  & thead {
    & tr {
      & th {
        text-align: left;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #002566;
        font-family: 'Poppins' !important;
        text-transform: uppercase !important;
        & span:nth-of-type(1) {
          flex-direction: row;
          & svg {
            opacity: 1 !important;
          }
        }
      }
      & th:last-of-type span svg {
        display: none;
      }
    }

  }
}
.table_pageination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 3vw;
  .rowNo_pageCounting {
    & p {
      margin-bottom: 0;
    }
    & .MuiTablePagination-actions {
      display: none;
    }
    & .MuiTablePagination-selectLabel {
      display: none;
    }
    & .MuiTablePagination-select {
      border: 1px solid #e8e9eb;
      padding: 0.5rem 2rem 0.5rem 0.5rem !important;
      border-radius: 10px;
      font-weight: 400;
      font-size: 15px;
      line-height: 21px;
      color: #3f434a;
    }
    & .MuiTablePagination-displayedRows {
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
      color: #8a9099;
    }
    & .MuiTablePagination-displayedRows::before {
      content: "Showing ";
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
      color: #8a9099;
    }
  }
  & .Table_Pagination_number {
    & ul {
      gap: 10px;
    }
    & li {
      background-color: #f8f8f8;
      border-radius: 10px;
    }
    & .Mui-selected {
      background-color: #002566;
      color: white;
    }
  }
}

.Active_statusButton {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #20a144;
  background-color: rgb(73 201 109 / 16%);
  padding: 0.3rem 1rem;
  border: none;
  border-radius: 10px;
}
.Disabled_statusButton {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #e56e19;
  background-color: rgb(255 150 93 / 16%);
  padding: 0.3rem 1rem;
  border: none;
  border-radius: 10px;
}
td {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #3f434a;
  font-family: 'Poppins' !important; 
}
// .row_action {

//   & input[type="checkbox"] {
//     &:checked ~ .row_action_list_container {
//       display: block;
//     }
//     &:checked ~ .row_action_list_container {
//       display: block;
//     }
//   }
//    .row_action_list_container{
//     position: relative;
//     display: none;
//    }
// } 
.more_option_manu{
  background-color: white;
  border: none;
}
.heading_and_close_container{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  button{
    background-color: #F8F8F8;
    border-radius: 10px;
    border: none;
    img{
      height: 1rem;
      width: auto;
    }
  }
}