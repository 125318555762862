.RecoverPasswordHeading{
    padding-bottom: 3vh !important;
}
.ResetLock{
    height: 15vh;
    width: auto;
    margin-bottom: 10vh;
}
.GoBackLogin{
font-weight: 400;
font-size: 15px;
line-height: 21px;
text-align: center;
color: #8A9099;
position: absolute;
bottom: 0;
a{
    text-decoration: none !important;
}
& span{
    color: #304ffd;
}
}