.MainContainer {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.displayFlexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom {
  height: 100vh;
  width: 100vw;
  background-color: #174bd6;
}
@font-face {
  font-family: "poppins";
  src: url(../../Fonts/poppins/Poppins-Regular.ttf);
}
.FormContainer {
  background-color: white;
  height: 100%;
  // width: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  flex-direction: column;
  position: relative;

  & .Login_heading {
    font-family: "poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 42px;
    color: #3f434a !important;
    padding-bottom: 10vh;
  }
  & .Login_button {
    width: 100%;
    background-color: #002566;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    border: none;
    box-shadow: 0px 3px 0px #004ed7;
  }
  & label {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: #8a9099;
  }
  & .LogingEmail,
  .LoginPasswoard {
    border: 1px solid #e8e9eb !important;
    // padding: 10/vh ;
    // background-color: transparent !important;
  }
  & .LogingEmail,
  .LoginPasswoard:focus {
    border: 1px solid #e8e9eb !important;
    outline: none !important;
    box-shadow: none !important;
    background-color: rgba(0, 0, 0, 0) !important;
  }
  & .rembercheckBox {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: #595f69;
  }
  //   & .rembercheckBox_input{
  //     border: 1px solid #8a9099;

  //   }
  .Lable_input_container {
    margin-bottom: 3vh;
    position: relative;
  }
  & .Forgot_Password_container h6 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: #304ffd;
    margin-bottom: 0;
  }
  & .Rember_forgot_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  & form {
    width: 80%;
  }
  & .afterEye{
    position: relative;
  }
  // & .afterEye::after {
  //   content: url("../../images/hide.png");
  //   height: auto;
  //   width: 5vh;
  //   position: absolute;
  //   top: 50%;
  //   right: 0;
  //   transform: translate(0, -50%);
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  // }
  & .afterEye {
    cursor: pointer;
    img{
      height: auto;
      width: 1vw;
      position: absolute;
      top: 50%;
      right: 5%;
      transform: translate(0, -50%);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    // .showPAssword{
    //   width: 2vw !important;
    // }
  }
  & .captcha-bg{
    background-color: #e2e2e2;
    margin-right: 10px;
  }
  & .captcha-refresh{
    cursor: pointer;
  }
 
}
.login_img_container img {
  height: 85vh;
  width: auto;
}
