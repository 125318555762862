.custom_boxInline {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 1rem;
}
.preview_Top_banner_container {
    // background-color: black;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 3rem 1rem 1rem 1rem;
    border-radius: 10px;
    margin-top: 1rem;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

  h6 {
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    color: #e9e9e9;
    background-color: #ff162b;
    display: inline-flex;
    padding: 0.2rem 0.5rem;
    margin-bottom: 0;
   
  }
  h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: #ffffff;
    margin-bottom: 0;
  }
  p {
    // font-family: 'SF Pro';
    // font-style: normal;
    font-weight: 300;
    font-size: 11px;
    line-height: 13px;
    color: #e9e9e9;
    margin-bottom: 0;
  }
}
.custom_preview_boxes_oneLine{
    display: flex;
    align-content: center;
    justify-content: space-between;
    margin-top: 1.5rem;
    
}

.skelaton_below_banner_container{
    display: flex;
    overflow: hidden;
    gap: 1rem;
    margin-right: -1rem;
    margin-top: 1rem;
    .skelaton_below_banner{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 0.3rem;
        
        
    }
}
.skeleton4_square_container{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.5rem;
    overflow: hidden;
    margin-right: -1rem;
    margin-top: 1rem;
}
.custom_sudo_fix_footer{
    background-color: white;
}