.table_heading_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.import_add_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1vw;
}

.import_headin_button {
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: #3f434a;

  background-color: white;
  border: none;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  font-size: 15px;

  & img {
    margin-right: 0.5vw;
  }
}

.Add_Head_Button {
  background-color: #002566;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 1rem;
  font-size: 14px;
}

//////////////////////////////////////////////////////
.input_button_model {
  width: 50vw;
}

.import_data_container {
  & h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 42px;
    color: #3f434a;
  }

  & .drap_n_drop_area {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #8a9099;
    border: 2px dashed #bebebe;
    border-radius: 10px;
    padding: 2rem;
    text-align: center;
    margin-bottom: 20px;

    & label {
      color: #002566;
      font-weight: 800;
    }
  }

  & .input_modal_footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & p {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #8a9099;
    }

    & button {
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      text-align: center;
      color: #595f69;

      padding: 0.5rem 1rem;
      background-color: white;
      border: 1px solid #e8e9eb;
      border-radius: 10px;
      width: 50%;
    }
  }
}

///////////////////////////////////////////////////////
.progress_bar_container {
  text-align: center;

  & h4 {
    color: #8a9099;
    text-align: center;
    margin-bottom: 3rem;

    & strong {
      font-weight: 700;
      font-size: 28px;
      line-height: 42px;
      color: #8a9099;
    }
  }

  & p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #8a9099;
    text-align: center;
    margin-top: 1rem;
  }

  & .progressBarColor {
    background-color: #d9d9d9;

    & span {
      background-color: #002466;
    }
  }
}

.import_cancel_container {
  width: 100%;
  text-align: right;

  & .import_cancel {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    text-align: right;
    color: #595f69;

    padding: 0.5rem 1rem;
    background-color: white;
    border: 1px solid #e8e9eb;
    border-radius: 10px;
  }
}

///////////////////////////////////////////////
.Error_message_container {
  text-align: center;

  & h6 {
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #ff162b;
  }

  & p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #8a9099;
  }

  .Reupload_button_container {
    width: 100%;
    text-align: right;
    margin-top: 2rem;

    & .import_Reupload {
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      text-align: right;
      color: #595f69;

      padding: 0.5rem 1rem;
      background-color: white;
      border: 1px solid #e8e9eb;
      border-radius: 10px;
    }
  }
}

//////////////////////////////////////////////////
.data_uploaded_container {
  text-align: center;

  & h4 {
    color: #8a9099;
    text-align: center;
    margin-bottom: 3rem;

    & strong {
      font-weight: 700;
      font-size: 28px;
      line-height: 42px;
      color: #8a9099;
    }
  }

  .Done_button_container {
    width: 100%;
    text-align: right;
    margin-top: 2rem;

    & .import_Done {
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      text-align: right;
      color: #595f69;

      padding: 0.5rem 1rem;
      background-color: white;
      border: 1px solid #e8e9eb;
      border-radius: 10px;
    }
  }
}