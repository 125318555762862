.researchPaper_paraButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  border: 1px solid #bebebe;
  border-radius: 10px;
  padding: 1rem;
  margin-top: 20px;
  p {
    font-weight: 300;
    font-size: 11px;
    line-height: 13px;
    color: #686868;
    margin-bottom: 0px;
  }
  button {
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    color: #111111;

    border: 1px solid #111111;
    border-radius: 10px;
    padding: 0.5rem 2rem;
  }
}
.inline_label_add{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;
  gap: 1rem;
  input{
    margin-bottom: 0;
  }
}