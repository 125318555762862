.MainHeader2 {
  height: 100%;
  width: 100%;

  & .HeaderContainer {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 3vw;

    & .midblackLine {
      border-right: 1px solid #e8e9eb;
      height: 6vh;
      width: auto;
    }

    & .profile_manage {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1vw;
      color: #3f434a;
      & h6 {
        
        margin-bottom: 0;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        color: #3f434a;

      }
    }
  }
}

.SerachNotificationContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1vw;
  // border-right: 1px solid #3F434A;
  & button {
    border: none;
    background-color: transparent;
  }
}
