.sidebarContainer {
//   background-color: red;
position: relative;
z-index: 1;
  & .MainGptwLogo{
    height: auto;
    width: 100%;
  }
  & header{
    box-shadow: 0px 3px 6px #E8E9EB;
  }

  & li.active{
    background-color: #161625
    !important;
    border-left: 2px solid #304FFD;

    & h5{
      color: white !important;
    }

  }
}
.MainHeader{
    background-color: white ;
    

}
.drawer_background div:nth-of-type(1) {
    background-color: #1e1f2d !important;
}

.sidebarList {
  background-color: #1e1f2d;
  ul {
    list-style: none;
    padding: 0rem;
    height: 100%;

    // display: flex;
    // align-items: flex-start;
    // flex-direction: column;
    // gap: 2vh;
    
  }
  & a {
    text-decoration: none;
  }
  & li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1vw;
    padding: 1.5vh 1vw;
    cursor: pointer;
  }
  & h5 {
    color: #66687f;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 0;
  }
}

.drawer_background div:nth-of-type(1)::-webkit-scrollbar {
  width: 3px !important; 
}
.drawer_background div:nth-of-type(1)::-webkit-scrollbar-thumb {
  background: #161625; 
}
.drawer_background div:nth-of-type(1)::-webkit-scrollbar-track {
  background: #1e1f2d; 
}
.custom_background{
  background-color: red;
}