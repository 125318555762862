.crop_image_container {
  .cropdemo_container {
    text-align: center;
    background-color: #3f434a;
  }
  h1 {
    font-weight: 500;
    font-size: 28px;
    line-height: 42px;
    color: #3f434a;
  }
  .crop_button_container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 2rem;
    button {
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      border-radius: 10px;
      padding: 0.5rem 2rem;
      border: 1px solid #002566;
    }

    button:nth-of-type(1){
        background-color: #002566;
        color: white;
    }
    button:nth-of-type(2){
        background-color: white;
        color: #002566;
    }
  }
}
