.main_container_add_banner_drawer {
  padding: 1rem;

  .banner_next_cancel_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;

    margin-top: 1rem;

    button:nth-of-type(1) {
      background-color: #002566;
      border-radius: 10px;
      padding: 0.5rem 2rem;
      border: 1px solid #002566;
      color: white;
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
    }

    button:nth-of-type(2) {
      background-color: white;
      border: none;
      color: red;
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
    }
  }

  .heading_add_drawer {
    font-weight: 500;
    font-size: 28px;
    line-height: 42px;
    color: #3f434a;
  }

  .eyebrow_title_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }

  label {
    margin-top: 1rem;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #8a9099;
  }
}


.custom_width_industry_dropdown {
  .For_chip_section {
    width: 100% !important;
  }
}

.title_radioButton_container {
  margin-top: 25px;
  padding: 0px 5px;

  h6 {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    /* identical to box height */
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #3F434A;
  }

  .member_free_button_container2 {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .member_type {
      margin-bottom: 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #595f69;
    }

    .free_premium_container {
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 15px;
      width: 65%;

      .free_Font {
        text-align: end;
      }

      & .free_Font,
      .premium_Font {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #a3a3a3;
        width: 100%;
      }
    }
  }
}