@font-face {
  font-family: NicoMojiFav;
  font-style: normal;
  src: url(../Fonts/NicoMoji/NicoMoji-Regular.ttf)format('truetype') !important;
  src: local(NicoMojiFav);
}
.pagenotfoundSec {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  & h1 {
    font-family: NicoMojiFav !important;
    font-weight: 400;
    font-size: 250px;
    line-height: 250px;
    text-align: center;
    color: #174bd6;
  }
  & h4 {
    
    font-weight: 500;
    font-size: 48px;
    line-height: 72px;
    text-align: center;
    color: #3f434a;
  }
  & p {
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: #595f69;
  }
  & button {
    background-color: #174bd6;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #ffffff;
    border: none;
    padding: 10px;
    border-radius: 15px;
    box-shadow: 0 2px 0px #002566;
    padding: 0.8rem 2rem;
  }
}
//  h1 {
//     font-family: "NicoMojiFav" !important;
//     font-weight: 400;
//     font-size: 250px;
//     line-height: 250px;
//     text-align: center;
//     color: #174bd6;
//   }