.users_profileImg_name_email_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .users_name_email_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
}
.addUserModel_container {
  background-color: rgba(0, 0, 0, 0.116);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 1101;
  // transform: translate(-50%,-50%);
  .mobile_no_main_container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    .phone_no_start_select {
      width: 8vw;
    }
  }

  .gcc_bec_main_container {
    
    padding-left: 1rem;

    .custo_FormGroup{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        label{
            span:nth-of-type(1){
                order: 2;
            }
            span:nth-of-type(2){
                order: 1;
            }
        }
    }
  }
}
.mobile_no_main_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  .phone_no_start_select {
    width: 8vw;
  }
}