.main_delete_container {
  background-color: rgba(0, 0, 0, 0.123);
  position: absolute;
  inset: 0;
  height: 100vh;
  width: 100vw;
  z-index: 9999;
  .delete_container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 2rem;
    padding: 2rem;
    border-radius: 15px;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-color: white;

    .delete_content {
      h1 {
        font-weight: 500;
        font-size: 28px;
        line-height: 42px;
        color: #111111;
      }
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #8a9099;
      }
      .delete_content_button_content {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 2rem;
        button {
          padding: 0.5rem 2rem;
          border-radius: 10px;
        }
        button:nth-of-type(1) {
          color: #ff162b;
          border: 1px solid #ffeaec;
          background-color: white;
          font-weight: 500;
          font-size: 15px;
          line-height: 22px;
        }
        button:nth-of-type(2) {
          color: white;
          border: 1px solid #002566;
          background-color: #002566;
          font-weight: 500;
          font-size: 15px;
          line-height: 22px;
        }
      }
    }
  }
}
