.modal_heading {
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  color: #3f434a;
}
.ModalPara {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #8a9099;
  margin-bottom: 10px;
}
.ADD_category_input {
  width: 100%;
  border: 1px solid #e8e9eb;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  margin-bottom: 10px;
}
.ADD_category_input:focus {
  border: 1px solid #e8e9eb;
  outline: none;
}
.category_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;

  & .category_tile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: #f8f8f8;
    gap: 10px;
    padding: 0.5rem 1rem;
    border: 1px solid #e8e9eb;
    border-radius: 10px;
    margin-bottom: 10px;
    position: relative;

    & .remove_category {
      background-color: transparent;
      border: none;
      position: relative;
      right: -10%;
    }
    & p {
      margin-bottom: 0px;
    }
  }
}
