.bestPeople_preview_tile {
  border: 1px solid #bebebe;
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 10px;
  h4 {
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    color: #000000;
  }
  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #111111;
  }
  p {
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #111111;
  }
  .logo_thumbsup_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
}
.logo_name_type_container {
  text-align: center;
  margin: 20px 0px;
  img {
    height: 60px;
    width: auto;
  }
  h4 {
    // font-family: "SFPro";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    color: #000000;
  }
  .industry_type_area_container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    border-top: 1px solid #bebebe;
    border-bottom: 1px solid #bebebe;
    padding: 10px 0px;
    span {
      //   font-family: "SFPro";
      font-style: normal;
      font-weight: 274;
      font-size: 12px;
      line-height: 16px;
      color: #686868;
    }
    h5 {
      margin-bottom: 0px;
      // font-family: 'SFPro';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 25px;

      color: #111111;
    }
    .for_border_right {
      border-right: 1px solid #bebebe;
      height: 3rem;
    }
  }
}
.BPP_preview_title_para {
  h6 {
    // font-family: "SFPro";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    color: #111111;
  }
  p {
    // font-family: "SFPro";
    font-style: normal;
    font-weight: 274;
    font-size: 16px;
    line-height: 25px;
    color: #111111;
    margin-bottom: 0;
  }
}
