body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "poppins";
  src: url(./Asset/Fonts/poppins/Poppins-Regular.ttf) !important;
}

* {
  font-family: "poppins";
}
html {
  overflow-x: hidden !important;
}
@font-face {
  font-family: "NicoMojiFav";
  src: url(./Asset/Fonts/NicoMoji/NicoMoji-Regular.ttf) !important;
}

@font-face {
  font-family: "NicoMojiFav";
  src: url(./Asset/Fonts/NicoMoji/NicoMoji-Regular.ttf) !important;
}

@font-face {
  font-family: "MFpro";
  src: url(./Asset/Fonts/NicoMoji/NicoMoji-Regular.ttf) !important;
}

table .Mui-checked {
  color: #002566 !important;
}
.MuiFormGroup-root .Mui-checked {
  color: #002566 !important;
}
.free_premium_container .Mui-checked {
  color: white !important;
}
.MuiCheckbox-indeterminate {
  color: #002566 !important;
}
tbody .Mui-selected {
  background-color: #f8f8f8 !important;
}
.custom_three_dropdown_option .Mui-checked {
  color: white !important;
}
.error-text {
  color: red;
}
.ellipse-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.MuiSwitch-switchBase.Mui-checked {
  color: #fff !important;
}
.MuiChip-colorDefault {
  background-color: #f8f8f8 !important;
}
.custom_table_checkbox svg {
  height: 20px !important;
  width: 20px !important;
}
.custom_table_checkbox {
  color: #e8e9eb !important;
  margin-right: 1rem !important;
}
.MuiPaper-elevation1 {
  border-radius: 1rem !important;
}
.custom_column_grey_color {
  color: #8a9099 !important;
}
.tox-tinymce {
  border: 1px solid lightgray !important;
}
.tox-tinymce .tox-statusbar__path-item,
.tox-statusbar__wordcount {
  display: none !important;
}
.tox-toolbar__group + .tox-toolbar__group {
  border-left: solid 1px #e8e9eb !important;
}
.preview_time_icons_container {
  margin-bottom: 0.5rem;
}
.Video_action_icon_container {
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
}
.publish_close_button_container button:nth-of-type(2) {
  font-weight: 600 !important;
}
.MuiPaper-elevation1 {
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset !important;
}



.form-group input, select{
  border-radius: 1rem !important;
}
form input{
  border-radius: 1rem !important;
}
.MuiOutlinedInput-input{
  padding: 8.5px 14px !important;
}
.custom_audio {
  border-radius: 1rem !important;
}
.Add_speaker_video_form fieldset {
  border-radius: 1rem !important;
}
.classShort{
  width: 35% !important;
}

table th,td{
  cursor: default !important;
}

.custom_copy_container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

}
.custom_copy_container button{
  background-color: transparent !important;
}