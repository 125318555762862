@import "rsuite/dist/rsuite.css";
.rs-picker-daterange-menu{
  z-index: 9999;
}
.customEventClass {
  background-color: hsl(188, 77%, 88%) !important;
  border-left: 3px solid #22cce2;
  border-right: 1px solid #22cce2;
  border-top: 1px solid #22cce2;
  border-bottom: 1px solid #22cce2;
  color: #3f434a;
  padding: 0.5rem !important;
  .fc-event-time {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #595f69 !important;
  }
  .fc-event-title {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #3f434a !important;
  }
}
.customEventClass33 {
  background-color: #fff4cf !important;
  border-left: 3px solid #ffd240;
  border-right: 1px solid #ffd240;
  border-top: 1px solid #ffd240;
  border-bottom: 1px solid #ffd240;
  
  color: #3f434a !important;
  padding: 0.5rem !important;
  .fc-event-time {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #595f69 !important;
  }
  .fc-event-title {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #3f434a !important;
  }
}
.list_of_speakers_container {
  & ul {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    & li {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;

      & img {
        height: 30px !important;
        width: auto;
      }
      & p {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #3f434a;
      }
    }
    & li:nth-last-of-type(1) p {
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      text-transform: uppercase;
      color: #002566;
    }
  }
}
.custom_time_date_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  .time_addTime_container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid lightgray;
    span {
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      color: #002566;
    }
    label{
      margin-bottom: 0.5rem;
    }
  }
  .custom_time_date {
    input {
      font-family: "Poppins";
    }
  }
}
.more_thumb_images_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  padding: 1rem 0;
  img {
    width: 20%;
    height: auto;
  }
}
.custom_add_event_container {
  h6 {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #002566;

    border-bottom: 1px solid #002566;
    padding-bottom: 0.5rem;
    text-transform: uppercase;
  }
}
.Add_event_heading_title {
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  color: #3f434a;

}
.custom_for_all_heights {
  min-height: 100vh !important;
  max-height: 100vh !important;
  overflow-y: auto !important;
}
.custom_for_all_heights::-webkit-scrollbar {
  width: 0 !important;
}
.Video_action_icon_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.speaker_video_footer {
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 2;
  background-color: white;
}
.custom_Eventpreview_Heading_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.5rem;
  h1 {
    margin-bottom: 0;
  }
  button {
    background-color: white;
    border: none;
  }
}
.upcoming_completed_container {
  background-color: #e7eaeb;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 1rem;
  button {
    border: none;
    font-weight: 590;
    font-size: 13px;
    line-height: 18px;
    width: 100%;
    border-radius: 50px;
    padding: 0.5rem 1rem;
  }
  button:nth-of-type(1) {
    background-color: #002566;
    color: #ffffff;
  }
  button:nth-of-type(2) {
    color: #94a5ab;
  }
}
.live_virtual_button_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  margin-top: 1rem;
  button {
    background-color: white;
    border: 1px solid black;
    border-radius: 10px;
    padding: 0.5rem 1rem;

    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    color: #111111;
  }
}
.custom_event_list_container {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  gap: 0.5rem;
  .event_list_detail {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0.2rem;
    p {
      margin-bottom: 0;
      font-weight: 510;
      font-size: 13px;
      line-height: 16px;
      color: #686868;
    }
    h6 {
      font-weight: 600;
      font-size: 16px;
      line-height: 25px;
      color: #111111;
      margin-bottom: 0;
    }
  }
  img {
    width: 100px;
    height: 80px;
  }
}
.event_name_calenderIcon_video_container {
  .event_preview_video {
    width: 100%;
    height: auto;
    margin-top: 1rem;
  }
  .event_name_calenderIcon_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h1 {
      font-weight: 700;
      font-size: 28px;
      line-height: 34px;
      color: #111111;
      margin-bottom: 0;
    }
    img {
      width: 20px;
      height: 25px;
    }
  }
}
.custom_book_know_buton_container {
  gap: 0.5rem;
  button:nth-of-type(1) {
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: #ffffff;
    background-color: #002566;
    border: 1px solid #002566;
    border-radius: 10px;
    padding: 0.5rem 1rem;
    width: 100%;
  }
  button:nth-of-type(2) {
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: #002566;
    background-color: white;
    border: 1px solid #002566;
    border-radius: 10px;
    padding: 0.5rem 1rem;
  }
}
