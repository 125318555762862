.action_dropdown_container {
  position: absolute;
  // top: 120%;
  right: 7%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.356);
  border-radius: 10px;
  padding: 1rem;
  z-index: 9;

  & img {
    margin-right: 10px;
    height: 15px;
    width: 15pxpx;
  }
  & .status_and_radio {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & button {
      width: max-content;
    }
    & .status_radio_button {
      margin-right: 0px;
      margin-left: 10px;

      // & .radio_button_background .MuiSwitch-track {
      //   background-color: #002566;
      // }
    }
  }

  & button {
    border: none;
    background-color: white;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #595f69;
  }
}
.custom_more_option_manu{
  top: auto;
}
.custom_three_dropdown_option{
  .custom_delete_button{
    padding-top: 20%;
    color: #FD7972;
  }
  .custom_hr_container{
    width: 100%;
    hr{
      width: 100%;
      position: absolute;
      top: auto;
      left: 0px;

      height: 1px;
      color: darkgray;
      background-color: darkgray;
    }
  }
}