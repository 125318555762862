.custom_tab_number {
  span {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
.custom_tab_number ::after {
  content: "123";
  display: flex;
  text-align: center;
  justify-content: end;
  background-color: #E8E9EB;
  margin-left: 10px !important;
  padding: 2px 5px;
  border-radius: 5px;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #8a9099;
}
