.Page_heading_container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & h1 {
    font-weight: 400;
    font-size: 28px;
    line-height: 42px;
    color: #3f434a;
  }

  & button {
    color: white;

    & img {
      margin-right: 10px;
    }
  }
}

.profileImg_name_container {
  & img {
    margin-right: 20px;
  }
}

.custom_table_css {
  & th,
  & td {
    font-family: "Poppins" !important;
  }
}

.upload_photo_container {
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 25vh;
  padding: 2rem;
  border: 2px dashed #c6c9cc;
  border-radius: 10px;
  text-align: center;

  & p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #3f434a;

    & label {
      color: #002566 !important;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.add_specker_form {
  & .form-group {
    margin-bottom: 15px;
  }

  & label {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #8a9099;
    margin-bottom: 0.3rem;
  }

  & #AddPhoto,
  #editPhoto {
    display: none;
  }

  & .done_cancel_container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;

    & button {
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
    }

    & .done_button {
      background-color: #002566;
      border: 1px solid #002566;
      padding: 0.5rem 2rem;
      color: white;
      border-radius: 1rem;
    }

    & .cancel_button {
      border: 1px solid #e8e9eb;
      padding: 0.5rem 2rem;
      color: #595f69;
      border-radius: 1rem;
    }
  }
}

.All_speaker_form_container {
  & h1 {
    font-weight: 500;
    font-size: 28px;
    line-height: 42px;
    color: #3f434a;
  }

  & .model_head_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // border-bottom: 1px solid lightgray;
    // width: 50%;

    & h6 {
      // margin-bottom: 0px;
      color: #002566;
      border-bottom: 1px solid #002566;
    }

    & button {
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      border: 1px solid #002566;
      color: #002566;
      background-color: white;
      border-radius: 10px;
      padding: 0.5rem 1rem;

      & img {
        margin-left: 10px;
      }
    }
  }
}

.tox-editor-header,
.toolbar__primary {
  background-color: #f8f8f8 !important;
}

.tox-toolbar-overlord {
  & div:nth-of-type(1) {
    background-color: #f8f8f8 !important;
  }
}

.tox-statusbar__branding {
  display: none !important;
}

.addSpeaker_special_main_container {
  .custom_height_modals {
    max-height: 90vh !important;
    width: 60vw !important;
    overflow-y: auto !important;
  }
}
