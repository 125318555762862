.Main_Modal_container {
  position: fixed;
  inset: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.116);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}
.modal_container {
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  position: relative;
  width: 40vw;

  & .CloseButton {
    position: absolute;
    top: 5%;
    right: 5%;
    border: none;
    background-color: transparent;
  }
  & .submitModalButton {
    border: none;
    border-radius: 10px;
    padding: 0.5rem 2rem;
    background-color: #002566;
    color: white;
  }
}
