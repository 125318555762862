.custom_datePicker {
  .MuiStack-root {
    overflow: hidden;
  }
  fieldset{
    border-radius: 15px !important;
  }
  input{
    padding-top: 15px !important;
  }
  .MuiFormControl-root{
    width: max-content !important;
  }
}
